import React, { useMemo } from 'react';
import './About.css'
import SliderPhoto from '../SliderPhoto';
import Sertifikat_1 from '../../images/Sertifikat-1.png'
import Sertifikat_2 from '../../images/Sertifikat-2.png'
import Footer from '../Footer';
import { useLang } from '../../redux/selectors';
import localData from "../../redux/localization/locale.json"

const About = () => {
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);

  return (
    <main>
      <div className="top-back">
        <div className="content">
          <h2>{langData['about']}</h2>
          <p>{langData['home']} / {langData['about']}</p>
        </div>
      </div>
      <div className="about-container">
        <div className="left-side">
          <SliderPhoto />
        </div>
        <div className="right-side">
          <p>
          {langData['about-p1']}<br /> <br />
          {langData['about-p2']}<br /> <br />
          {langData['about-p3']}<br /> <br />
          {langData['about-p4']}<br /> <br />
          {langData['about-p5']}<br /><br />
          {langData['about-p6']} <br /> <br />
          {langData['about-p7']} <br /> <br />
          {langData['about-p8']} <br /><br />
          {langData['about-p9']}
          </p>
        </div>
      </div>
      <div className="ser-container">
      <div className="image-container">
        <img src={Sertifikat_1} alt="" />
        <img src={Sertifikat_2} alt="" />
      </div>
    </div>
    <Footer />
    </main>
  );
};

export default About;
