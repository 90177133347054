import axios from "axios";

const BASE_URL = "https://admin.mazzalitravel.uz/api/";
export const BASE_IMG = "https://admin.mazzalitravel.uz/images/";

export const getAllNews = () => {
    return axios.get(BASE_URL + 'news')
}

export const getAllToursNam = () => {
    return axios.get(BASE_URL + 'namangan')
}
export const getAllToursAnd = () => {
    return axios.get(BASE_URL + 'andijon')
}
export const getAllToursFer = () => {
    return axios.get(BASE_URL + 'fargona')
}
export const getAllToursKaz = () => {
    return axios.get(BASE_URL + 'qozogiston')
}
export const getAllToursKir = () => {
    return axios.get(BASE_URL + 'qirgiziston')
}
export const getAllToursTad = () => {
    return axios.get(BASE_URL + 'tojikiston')
}
export const getAllToursTash = () => {
    return axios.get(BASE_URL + 'toshkent')
}
export const getAllToursGal = () => {
    return axios.get(BASE_URL + 'gallery')
}


export function formatDateTime(inputDate) {
    let dateObj = new Date(inputDate);

    let year = dateObj.getFullYear();
    let month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    let day = dateObj.getDate().toString().padStart(2, '0');
    // let hours = dateObj.getHours().toString().padStart(2, '0');
    // let minutes = dateObj.getMinutes().toString().padStart(2, '0');

    // let formattedTime = `${hours}:${minutes}`;

    return `${year}-${month}-${day}`;

}