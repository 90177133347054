import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,

} from "react-router-dom";
import Navbar from './components/Navbar';
import Header from './components/Header';
import Homepage from './components/Homepage'
import ScrollHandler from './components/ScrollHandler';
import Card from './components/Card'
import SliderPart from './components/SliderPart';
import NewsPage from './components/NewsPage';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


import '../src/App.css'
import '@fortawesome/fontawesome-free/css/all.min.css';
import Footer from './components/Footer';
import Card2 from './components/Card2';
import About from './components/Pages/About';
import Tours from './components/Pages/Tours';
import TourAndijan from './components/Tours/TourAndijan';
import TourNamangan from './components/Tours/TourNamangan';
import TourFergana from './components/Tours/TourFergana';
import TourTashkent from './components/Tours/TourTashkent';
import TourKazak from './components/Tours/TourKazak';
import TourKirgiz from './components/Tours/TourKirgiz';
import TourTadjik from './components/Tours/TourTadjik';
import Gallery from './components/Pages/Gallery';
import ToursPage from './components/Pages/TourPages';
import News from './components/Pages/News';
import Contact from './components/Pages/Contact';
import Testimonial from './components/Testimonial';
import NewsPages from './components/Pages/NewsPages';



function Home() {
  return <div className="App">
    <Homepage />
    <Card />
    <Card2 />
    <SliderPart />
    <NewsPage />
    <Testimonial />
    <Footer />
  </div>;
}

function App() {
  return (
    <Router>
      <ScrollHandler>
        <Navbar />
      </ScrollHandler>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/tours" element={<Tours />} />
        <Route path="/andijan" element={<TourAndijan />} />
        <Route path="/namangan" element={<TourNamangan />} />
        <Route path="/fergana" element={<TourFergana />} />
        <Route path="/tashkent" element={<TourTashkent />} />
        <Route path="/kazakhstan" element={<TourKazak />} />
        <Route path="/kirgizistan" element={<TourKirgiz />} />
        <Route path="/tadjikistan" element={<TourTadjik />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/tours/:id" element={<ToursPage />} />
        <Route path="/item/:id" element={<NewsPages />} />
        <Route path="/news" element={<News />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}



export default App;
