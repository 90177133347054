import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import './Tours.css'; // Assuming your CSS file is named App.css
import tours_1 from '../../images/Tours/Tours-1.jpg'
import tours_2 from '../../images/Tours/Tours-2.jpg'
import tours_3 from '../../images/Tours/Tours-3.jpg'
import tours_4 from '../../images/Tours/Tours-4.jpg'
import tours_5 from '../../images/Tours/Tours-5.jpg'
import tours_6 from '../../images/Tours/Tours-6.jpg'
import tours_7 from '../../images/Tours/Tours-7.jpg'
import tours_8 from '../../images/Tours/Tours-8.jpg'
import tours_fer2 from '../../images/ToursFergana/ToursFer-2.jpg';
import tours_fer4 from '../../images/ToursFergana/ToursFer-4.jpg';
import { useLang } from '../../redux/selectors';
import localData from "../../redux/localization/locale.json"

import Footer from '../Footer';

const tours = [
    {
        id: 3,
        title: 'Программа однодневной экскурсии в Риштан.',
        description: 'Туры май 27, 2024',
        image: tours_1,
        details: [
            // Details here...
        ]
    },
    {
        id: 5,
        title: 'Завораживающий Заамин',
        description: 'Туры май 27, 2024',
        image: tours_2
    },
    {
        id: 6,
        title: 'Историческое путешествие в Туркестан!',
        description: 'Туры май 27, 2024',
        image: tours_3
    },
    {
        id: 7,
        title: 'Путешествие в Таджикистан',
        description: 'Туры май 27, 2024',
        image: tours_4
    },
    {
        id: 8,
        title: 'Тур в Арсланбоб.',
        description: 'Туры май 27, 2024',
        image: tours_5
    },
    {
        id: 9,
        title: 'Тур в Сары-Челек.',
        description: 'Туры май 27, 2024',
        image: tours_6
    },
    {
        id: 10,
        title: 'Путешествие в Ош и Узген',
        description: 'Туры май 27, 2024',
        image: tours_7
    },
    {
        id: 1,
        title: 'Коканд и дынный рай Андархан',
        description: 'Туры май 27, 2024',
        image: tours_8
    },
    {
        id: 2,
        title: 'Программа экскурсии в Маргилан.',
        description: 'Туры май 27, 2024',
        image: tours_fer2
    },
    {
        id: 4,
        title: 'Шоппинг тур в Риштан и Маргилан',
        description: 'Фергана янв 16, 2024',
        image: tours_fer4
    }
];

const Tours = () => {
    const lang = useLang();
    const langData = useMemo(() => localData[lang], [lang]);

    return (
        <main>
            <div className="top-back">
                <div className="content">
                    <h2>{langData['tours']}</h2>
                    <p>{langData['home']} / {langData['tours']}</p>
                </div>
            </div>
            <div className="tours-container">
                <h1 className="tours-header">{langData['our-tours']}</h1>
                <div className="card-tours">
                    {tours.map(tour => (<Link to={`/tours/${tour.id}`} className="details-link">
                        <div className="tours-card" key={tour.id}>
                            <img src={tour.image} alt={tour.title} />
                            <div className="card-content">
                                <div className="card-title">{tour.title}</div>
                                <div className="card-description">{tour.description}</div><br />
                            </div>
                        </div>
                    </Link>
                    ))}
                </div>
            </div>
            <Footer />
        </main>
    );
};

export default Tours;