import React, { useMemo, useEffect, useState } from 'react';
import './NewsPage.css';
import { BASE_IMG, formatDateTime, getAllNews } from '../api';
import { useLang } from '../redux/selectors';
import localData from "../redux/localization/locale.json"
import { Link } from 'react-router-dom';


const NewsPage = () => {
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);
  const [newsData, setNewsData] = useState([]);


  useEffect(() => {
    getAllNews().then(({ data }) => {
      setNewsData(data);
    }).catch((error) => {
      console.error(error);
    })
  }, [])

  return (
    <div className="container-news">
      <header>
        <h2 className='news-h2'>- {langData['news']} -</h2>
        <h1 className='news-h1'>{langData['recent-news']}</h1>
      </header>
      <div className="cards">
        {newsData.map(item => (<Link to={`/item/${item.id}`} className="details-link">
          <div className="card" key={item.id}>
            <img src={BASE_IMG + item.img} alt={item.title_en} />
            <strong>{item[`title_${lang}`]}</strong>
            <p>{formatDateTime(item.created_at)}</p>
          </div>
        </Link>
        ))}
      </div>
    </div>
  );
};

export default NewsPage;