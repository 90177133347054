import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../Footer';
import './NewsPages.css';
import news_2 from '../../images/News/News-2.jpg'
import news_1 from '../../images/News/News-1.jpeg'
import news_3 from '../../images/News/News-3.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';

import gallery_1 from '../../images/PhotoGallery/PhotoSlide-1.jpg'
import gallery_2 from '../../images/PhotoGallery/PhotoSlide-2.jpg'
import gallery_3 from '../../images/PhotoGallery/PhotoSlide-3.jpg'
import gallery_4 from '../../images/PhotoGallery/PhotoSlide-4.jpg'
import gallery_5 from '../../images/PhotoGallery/PhotoSlide-5.jpg'




import { Pagination } from 'swiper/modules';


import 'swiper/css';
import 'swiper/css/pagination';

const galleryImages = [gallery_1, gallery_2, gallery_3, gallery_4, gallery_5];


const newsItems = [
    {
        id: 1,
        imgSrc: news_2,
        altText: "Placeholder",
        description: "На фотографиях - узбекский виноградарь. Он был ученым с мировым именеm.",
        date: "16.янв.2024",
    },
    {
        id: 2,
        imgSrc: news_1,
        altText: "Placeholder",
        description: "Тур в Чадак",
        date: "16.янв.2024",
    },
    {
        id: 3,
        imgSrc: news_3,
        altText: "Placeholder",
        description: "Однодневный тур в Учкурган.",
        date: "16.янв.2024",
    },
];

const NewsPages = () => {
    const { id } = useParams();
    const item = newsItems.find(t => t.id === parseInt(id));

    if (!item) {
        return <div>Тур не найден</div>;
    }

    return (
        <main>
            <div className="top-back">
                <div className="content">
                    <h2 className='title-text' style={{ fontSize: '50px' }}>{item.description}</h2>
                </div>
            </div>
            <div className="page-tours">
                <div className="tours-page">
                    <img src={item.imgSrc} alt={item.title} className="tours-part-image" />
                    <p style={{ color: '#000' }}>{item.description}</p>
                    <p style={{ color: '#000' }}>{item.date}</p>

                    <Swiper
                        spaceBetween={30}
                        pagination={{ clickable: true }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {galleryImages.map((src, index) => (
                            <SwiperSlide key={index}>
                                <img src={src} alt={`Gallery Image ${index + 1}`} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <Footer />
        </main>
    );
};

export default NewsPages;
