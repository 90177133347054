import React, { useMemo } from 'react';
import Footer from '../Footer';
import NewsPage from '../NewsPage';
import { useLang } from '../../redux/selectors';
import localData from "../../redux/localization/locale.json"


const News = () => {
    const lang = useLang();
    const langData = useMemo(() => localData[lang], [lang]);

    return (
        <main>
            <div className="top-back">
                <div className="content">
                    <h2>{langData['news']}</h2>
                    <p>{langData['home']} / {langData['news']}</p>
                </div>
            </div>
            <NewsPage />
            <Footer />
        </main>
    );
};

export default News;