import React, { useMemo } from 'react';
import './SliderPart.css';
import SliderPhoto from './SliderPhoto';
import { useLang } from '../redux/selectors';
import localData from "../redux/localization/locale.json"

const SliderPart = () => {
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);

  return (
    <div className="page">
      <header>
        <h2 className='home-h2'>- {langData['photo-gallery']} -</h2>
        <h1 className='home-h1'>{langData['photos-tourists']}</h1>
      </header>
      <div className="centered-div">
        <SliderPhoto />
      </div>
    </div>
  );
}

export default SliderPart;