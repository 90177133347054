import React, { useMemo }  from 'react';
import { Link } from "react-router-dom";
import Place1 from '../images/Place1.png'
import Place2 from '../images/Place2.png'
import Place3 from '../images/Place3.png'
import Place4 from '../images/Place4.png'
import { useLang } from '../redux/selectors';
import localData from "../redux/localization/locale.json"


import './Card.css';

const Card = () => {
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);

  return (
    <div className="home-page">
       <Link to="/tours"><button class="button-31" role="button">{langData['all-tours']}</button></Link>
      <h1 className='home-1'>{langData['we-offer']}</h1>
      <div className="image-grid">
        <div className="image-item">
      <div className="overlay"></div>
          <img src={Place1} alt="" />
          <Link to="/namangan" className='image-text'>{langData['namangan']}</Link>
        </div>
        <div className="image-item">
        <div className="overlay"></div>
          <img src={Place2} alt="" />
          <Link to="/fergana" className='image-text'>{langData['fergana']}</Link>
        </div>
        <div className="image-item">
        <div className="overlay"></div>
          <img src={Place3} alt="" />
          <Link to="/andijan" className='image-text'>{langData['andijan']}</Link>
        </div>
        <div className="image-item">
        <div className="overlay"></div>
          <img src={Place4} alt="" />
          <Link to="/tashkent" className='image-text'>{langData['tashkent']}</Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
