import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../Footer';
import './ToursPage.css';
import tours_2 from '../../images/Tours/Tours-2.jpg';
import tours_3 from '../../images/Tours/Tours-3.jpg';
import tours_4 from '../../images/Tours/Tours-4.jpg';
import tours_5 from '../../images/Tours/Tours-5.jpg';
import tours_6 from '../../images/Tours/Tours-6.jpg';
import tours_7 from '../../images/Tours/Tours-7.jpg';
import tours_fer1 from '../../images/ToursFergana/ToursFer-1.jpg';
import tours_fer2 from '../../images/ToursFergana/ToursFer-2.jpg';
import tours_fer3 from '../../images/ToursFergana/ToursFer-3.jpg';
import tours_fer4 from '../../images/ToursFergana/ToursFer-4.jpg';
import tours_nam1 from '../../images/ToursNamangan/ToursNam-1.jpg'
import tours_nam2 from '../../images/ToursNamangan/ToursNam-2.jpg'
import tours_nam3 from '../../images/ToursNamangan/ToursNam-3.jpg'
import tours_nam4 from '../../images/ToursNamangan/ToursNam-4.jpg'
import tours_nam5 from '../../images/ToursNamangan/ToursNam-5.jpg'
import tours_nam6 from '../../images/ToursNamangan/ToursNam-6.jpg'
import tours_nam7 from '../../images/ToursNamangan/ToursNam-7.jpg'
import tours_nam8 from '../../images/ToursNamangan/ToursNam-8.jpg'
import tours_nam9 from '../../images/ToursNamangan/ToursNam-9.jpg'
import tours_nam10 from '../../images/ToursNamangan/ToursNam-10.jpg'
import tours_nam11 from '../../images/ToursNamangan/ToursNam-11.jpg'
import tours_nam12 from '../../images/ToursNamangan/ToursNam-12.jpg'

const tours = [
    {
        id: 1,
        title: 'Коканд и дынный рай Андархан',
        description: 'Фергана май 27, 2024',
        image: tours_fer1,
        details: [
            '06.45. - Встреча возле Эйфелевой башни, 5а микрорайон.',
            '09.00. - Прибытие в Коканд. Начало экскурсии.',
            'Экскурсия:',
            '•Дом Вадьяева-хлопкоторговца,сейчас  это здание хокимията;',
            '•Русско-азиатский банк,сейчас это здание Агробанка;',
            '•Дом Потеляхова. Телеграф;',
            '•Государственный банк,сейчас Литературный музей;',
            '•Дом братьев Мандалаки,гренажное производство;',
            '•Дом Кнабе,директора Русско-азиатского банка;',
            '•Особняки братьев Симхаевых;',
            '•Арт-резидеция.',
            '12.00. - Обед.',
            '13.00. - Выезд в Андархан.',
            '14.00. - Прибытие в Андархан. Начало экскурсии.',
            'Именно здесь хранятся зимние дыни. И мы увидим своими глазами эти чудо склады. Ну и конечно же продегустируем.',
            '15.00. - Выезд в Наманган.',
            '18.00. - Приезд в Наманган. К Эйфелевой башне в 5а микрорайон.',
            'В стоимость входит:',
            '-Транспорт Наманган - Коканд - Андархан- Наманган;',
            '-Обед;',
            '-Квалифицированный экскурсовод в Коканде;',
            '-Услуги сопровождающего гида.'
        ]
    },
    {
        id: 22,
        title: 'Программа экскурсии в Маргилан.',
        description: 'Фергана май 27, 2024',
        image: tours_fer2,
        details: [
            '07.00. – Встреча с гостями на стоянке возле Эйфелевой башни, в 5а микрорайоне.',
            '07.20. - Отправление в Маргилан на комфортабельном транспорте.',
            '09.00. – Прибытие в Маргилан. Посещение архитектурного комплекса Пир Сиддик.',
            '10.15. – Посещение центра ремесленников Кумтепа.',
            '11.30. – Посещение Медресе Саид Ахмад Ходжа, воздвигнутого в конце 19 века. В настоящее время в нём располагается центр национальных ремёсел.',
            '13.00. – Обед в маргиланской чайхане.',
            '14.30. – Посещение самого крупного в Центральной Азии Шоурум «Gold Silk». Здесь Вы увидите всё разнообразие и богатство маргиланского шёлка.',
            '15.45. – Посещение мавзолея Ходжи-Магиза.',
            '16.45. – Отправление в Наманган.',
            '18.30. – Прибытие в Наманган.',
            'Конец программы.',
            'В стоимость программы включено:',
            '- Комфортабельный автобус;',
            '- Сопровождающий гид;',
            '- Обед в чайхане;',
            '- Входные билеты на памятники в Маргилане.'
        ]
    },
    {
        id: 3,
        title: 'Программа однодневной экскурсии в Риштан.',
        description: 'Фергана май 27, 2024',
        image: tours_fer3,
        details: [
            '07.00. - Встреча возле Эйфелевой башни, 5а микрорайон.',
            '10.00. - Прибытие в Риштан. Начало экскурсии.',
            'Экскурсия по Риштану:',
            '• Посещение керамической мастерской;',
            '• Посещение местного рынка;',
            '12.00. - Обед.',
            '13.00. - Продолжение экскурсии.',
            '15.00. - Свободное время.',
            '16.00. - Выезд в Наманган.',
            '19.00. - Приезд в Наманган. К Эйфелевой башне в 5а микрорайон.'
        ]
    },
    {
        id: 1,
        title: 'Шоппинг тур в Риштан и Маргилан',
        description: 'Фергана янв 16, 2024',
        image: tours_fer4,
        details: [
            '06.45. – Встреча с гостями возле Эйфелевой башни.',
            '07.00.- Отправление в Риштан.',
            '09.00. – Прибытие в Риштан. Керамический базар. Здесь Вы можете приобрести керамическую посуду, сувениры, различные фигурки для сада и многое другое. От цен на душе становится очень хорошо!',
            '10.30. – Посещение керамического центра. Здесь располагаются мастерские 20 мастеров. И здесь Вы найдёте керамику на любой вкус.',
            '12.00. Обед. Как же без риштанского плова))',
            '13.30. – Выезд в Маргилан.',
            '14.30. – Фабрика «MargilanSilk». Изготовление и окраска тканей вручную. Мастер класс.',
            '15.30. – Посещение магазина (шоу рум) «MargilanSilk». Здесь Вы сможете приобрести очень красивые изделия из национальных тканей.',
            '17.00. - Отправление в Наманган. По дороге остановка в «Урам Самоваре». Нухат шурак.',
            '19.00. - Прибытие в Наманган, к Эйфелевой башне.',
            'В стоимость программы включено:',
            ' Транспорт;',
            '- Обед в чайхане, плов;',
            '- Сопровождающий.',
        ]
    },
    {
        id: 5,
        title: 'Завораживающий Заамин',
        description: 'Туры май 27, 2024',
        image: tours_2,
        details: [
            'Заамин – это место, где природа раскрывает свои волшебные тайны. Готовьтесь кзахватывающим пейзажам, невероятной уникальности и красоте. Вместе мы отправимся в самое',
            'сердце Заамина.',
            'В стоимость включено:',
            '- Проживание в отеле Wyndham Garden  4*;',
            '- Питание (1 завтрак, 2 обеда, 1 ужин);',
            '- Транспорт по всему маршруту (Наманган - Ташкент - Наманган включено);',
            '- Услуги сопровождающего;',
            '- Экскурсии, дегустации, мастер-классы;',
            '- Музыкальное сопровождение.',
            ' Готовы отправиться в невероятное путешествие? Запишитесь прямо сейчас и подарите себе',
            'удивительные воспоминания!'
        ]
    },
    {
        id: 6,
        title: 'Историческое путешествие в Туркестан!',
        description: 'Туры май 27, 2024',
        image: tours_3,
        details: [
            'Присоединяйтесь к двухдневному туру в -Туркестан, где мы посетим мавзолей Ходжи Ахмеда Яссави и другие исторические места!',
            'Стоимость тура на человека 1.800.000 сум',
            'В стоимость включено:',
            '🏨 Проживание в отеле',
            '🍇 Завтрак и обед',
            '🚘 Трансфер по всему маршруту',
            '🤵🏻‍♂️ Услуги профессионального гида-историка',
            '🔥 Сопровождающий от компании',
            '🕌 Экскурсии',
            '🔸 Входные билеты',
            'Не входит: Ужин'
        ]
    },
    {
        id: 7,
        title: 'Путешествие в Таджикистан',
        description: 'Туры май 27, 2024',
        image: tours_4,
        details: [
            '3 дня/2 ночи',
            'День 1.',
            'Наманган – граница «Андархан» - Худжанд.',
            '06.00. – Встреча с гостями. Отправление на границу «Андархан».',
            '08.30. - Прохождение узбекско – таджикской границы.',
            '09.00. -  Встреча с местным гидом.',
            '10.00. - Завтрак по дороге в кафе.',
            '11.00. - Выезд в Худжанд.',
            '12.00. - Прибытие в Худжанд и заселение в гостиницу "Сугдиён» 4*. Размещение в 2- х местных номерах.',
            '13.00. - Обед во Дворце Плова.',
            'На обед худжандский плов.',
            '14.00. - Начало экскурсионной программы.',
            'Посещение Худжандской крепости и Краеведческого музея.',
            'Прогулка вдоль крепостных стен крепости Тимур Малика, осмотр руин крепостной стены 17 века',
            '16.00. - Прогулка на Канатной дороге через историческую часть города и реку Сырдарью. Катание на катере по Сырдарье.',
            '17.00. - Посещение и осмотр памятника Исмоилу Сомони, национальному герою и основоположнику таджикской государственности.',
            '17.00. - Возвращение в отель. Свободное время.',
            '19.00. - Ужин в национальном стиле: худжандские шашлыки.',
            '20.30. - Возвращение в отель «Сугдиён».',
            'По желанию вечерняя прогулка в парке Камолиддина Худжанди.',
            'Ночной отдых.',
            'День 2.',
            '08.00. – 09.00. - Завтрак в отеле.',
            '09.00. - Посещение одного из самых больших базаров в Средней Азии - базара Панчшанбе.',
            'Осмотр исторических достопримечательностей:',
            'Мечети и мавзолея Шейха Муслихитдина .',
            '10.30. - Посещение уникального архитектурного ансамбля "Арбоб", называемого "Восточным Петергофом".',
            '12.00.-13.00. - Обед в Худжанде.',
            '13.00-15.00. - Посещение дома-музея коллекционера Нозимжон ака.',
            '15.00.-18.00. - Прогулка по парку Худжанди. Свободное время.',
            '18.00. - Ужин. Отдых.',
            'День 3.',
            '08.00.-09.00. - Завтрак. Выезд из гостиницы. Выезд на Кайракумское водохранилище.',
            '10.00. - Кайракумское водохранилище. Катание на катере. Отдых.',
            '13.00. - Обед.',
            '14.00. - Выезд на таджикско-узбекскую границу.',
            '16.00. – Прибытие на КПП «Андархан». Переход таджикско-узбекской границы.',
            '16.30. - Выезд в Наманган.',
            '19.30. – Прибытие в Наманган.',
            'В стоимость тура включено:',
            '- Двухместное размещение в отеле «Сугдиён» с завтраком;',
            '- Транспорт по всему маршруту;',
            '- Питание (1 завтрак,3 обеда, 2 ужина);',
            '- Профессиональный гид в Таджикистане;',
            '- Входные билеты на объекты посещения;',
            '- Услуги сопровождающего.'
        ]
    },
    {
        id: 8,
        title: 'Тур в Арсланбоб.',
        description: 'Туры май 27, 2024',
        image: tours_5,
        details: [
            'Направление тура: Наманган – Арсланбоб - Наманган .',
            'Продолжительность: 2 дня / 1 ночь',
            '06.00.- Сбор в 5а микр. возле Эйфелевой башни.',
            '06.30.- Выезд на границу с Кыргызстаном. КПП «Маданият», Избосканский район Андижанская область.',
            '08.00.- Прибытие на границу. Переход узбекско-кыргызской границы через КПП "Маданият"',
            '09.00.- Отправление в Арсланбоб.',
            '13.00.- Прибытие в Арсланбоб. Размещение в отеле.',
            '13.30.- Обед.',
            '14.30.- Выезд на малый водопад. Прогулка по базару.',
            '18.30.- Возвращение в гостиницу. Свободное время.',
            '19.00. - Ужин. Дискотека.',
            '21.00. – Свободное время, ночной отдых.',
            'День 2.',
            '07.30.- Завтрак.',
            '08.30.- Выезд на панораму.',
            '10.30. - Посещение большого водопада. Свободное время.',
            '12.00. – Обед у большого водопада.',
            '14.00.- Отправление на границу с Узбекистаном.',
            '18.00.- Прибытие на границу с Узбекистаном. Переход кыргызско-узбекской границы через КПП "Маданият".',
            '19.00.- Выезд в Наманган',
            '20.30.- Прибытие в Наманган.',
            'Окончание экскурсионной программы.',
            'В стоимость программы включено:',
            '- Транспорт по всему маршруту (микроавтобусы);',
            '- Проживание в гостевом доме (2х-3х местное размещение);',
            '- Питание (2 обеда, 1 завтрак, 1 ужин);',
            '- Услуги сопровождающего гида;',
            '- Медицинская страховка;',
            '- Минеральная вода.',
        ]
    },
    {
        id: 9,
        title: 'Тур в Сары-Челек.',
        description: 'Туры май 27, 2024',
        image: tours_6,
        details: [
            'Направление тура: Наманган - Сары-Челек - Наманган.',
            'Продолжительность: 3 дня / 2 ночи.',
            'День 1.',
            '06.30.- Встреча с гостями в 5а микр. Возле Эйфелевой башни.',
            '06.00.- Выезд на границу с Кыргызстаном.',
            '08.00.- Прибытие на границу. Переход узбекско-кыргызской границы.',
            '09.00. – Отправление в Сары-Челек.',
            '12.00.- Прибытие в Сары-Челекский биосферный заповедник.',
            '12:30.- Размещение в гостевом доме на территории заповедника.',
            '13.00.- Обед.',
            '14:30.- Выезд на озеро Сары-Челек.',
            'Свободное время для прогулок, купания и катания на катере.',
            '18.00.- Возвращение в гостевой дом. Свободное время.',
            '19.30. - Ужин. После ужина дискотека.',
            '22.00. – Ночной отдых.',
            'День 2.',
            'День 2.',
            '09.30.- Прогулка на лошадях или пешком до верхних озер.',
            '12.30.- Обед и отдых на природе.',
            '16.00. – Возвращение в гостевой дом. Свободное время.',
            '19.30. – Ужин. После ужина дискотека.',
            '22.00. – Ночной отдых.',
            'День 3.',
            '08.00. – Завтрак.',
            '09.00. – Выезд к Эоловым скалам, обзорная экскурсия.',
            '12.00. – Обед в кафе. Манты.',
            '13.00.- Отправление на границу с Узбекистаном.',
            '17.00.- Прибытие на границу с Узбекистаном. Переход кыргызско-узбекской границы через КПП',
            '"Касансай".',
            '18.00.- Выезд в Наманган',
            '19.00.- Прибытие в Наманган.',
            'В стоимость программы включено:',
            '- Комфортабельный транспорт по всему маршруту;',
            '- Проживание в гостевых домах (2х-3х местное размещение);',
            '- Питание (2 завтрака, 3 обеда, 2 ужина);',
            '- Услуги сопровождающего гида;',
            '- Входные билеты в заповедник;',
            '- Минеральная вода.',
            'Катание на лошадях и прогулка на катере оплачиваются дополнительно. Катание на катере только с разрешения администрации.'
        ]
    },
    {
        id: 10,
        title: 'Путешествие в Ош и Узген',
        description: 'Туры май 27, 2024',
        image: tours_7,
        details: [
            'Направление тура: Наманган - Сары-Челек - Наманган.',
            '2 дня / 1 ночь.',
            'День 1.',
            '06.30.- Встреча с гостями в 5а микр. Возле Эйфелевой башни.',
            '06.30. – Отправление на границу, пост «Дустлик»',
            '08.30. – Прибытие на КПП " Дустлик", переход узбекско-кыргызской границы.',
            'Обмен денег на Кыргызской стороне.',
            'Отправление в Ош.',
            'Размещение в отеле.',
            'Поздний завтрак в отеле.',
            'Начало экскурсионной программы.',
            'Обзорная экскурсия по г. Ош с посещением "сувенирного магазина" (кыргызские вина и коньяки.)',
            'Обед в кафе.',
            'Продолжение экскурсионной программы:',
            'Посещение горы Сулайман Тоо и краеведческого музея с экскурсиями.',
            'Посещение Ошского базара.',
            'Ужин в городе.',
            'После ужина возвращение в отель и свободное время.',
            'Ночной отдых.',
            'День 2.',
            'Завтрак в отеле.',
            'Экскурсионная поездка в Узген. Посещение архитектурного комплекса, осмотр Узгенской башни и караханидских мавзолеев.',
            'Возвращение в Ош. Обед - плов из узгенского риса.',
            '14.00. – отправление на кыргызско – узбекскую границу «Дустлик».',
            'Переход границы. Отправление в Наманган.',
            'Прибытие в Наманган.',
            'В стоимость тура включено:',
            '- Двух-трехместное размещение отеле 3*;',
            '- Транспорт по всему маршруту;',
            '- Питание (2 завтрака, 2 обеда, 1 ужин);',
            '- Профессиональный гид в Кыргызстане;',
            '- Сопровождающий гид;',
            '- Входные билеты на объекты посещения.',
        ]
    },
    {
        id: 2,
        title: 'Программа однодневной экскурсии в Ахсикент и Чуст.',
        description: 'Наманган май 27, 2024',
        image: tours_nam1,
        details: [
            'Продолжительность: 1 день.',
            '08.00. – Встреча с гостями у «Эйфелевой башней» в 5а микр.',
            '08.30 - Отправление в Ахсикент на комфортабельном транспорте.',
            '09.15. – Прибытие в Ахсикент. Ахсикент - древнее городище на правом берегу Сырдарьи, на территории современной Наманганской области. В 10 – 13 веках Ахсикент был одним из крупных городов Ферганской долины. Территория поселения составляла более 70 гектаров.',
            'Несмотря на то, что в 13 веке Ахсикент был разрушен во время монгольского нашествия, даже в 15 веке он считался крупным городом. Отец Бобура Умар-Шейх-Мирза, будучи правителем Ферганы, избрал Ахси своей резиденцией. В 1620 году город был полностью разрушен землетрясением. Население Ахсикента, уцелевшее после землетрясения, переселилось в соседний Наманган.',
            '10.15. – Переезд в Новый Ахсикент и осмотр его достопримечательностей. Посещение новой, красивой пятничной мечети «Хужа Калон Абдулмоний». Знакомство с редкими, ценными экспонатами, хранящимися в местном этнографическом музее.',
            '11.15. – Посещение мавзолея Лянгар биби.',
            '12.00. – Отправление в Чуст.',
            '12.30. – Прибытие в Чуст. Обед в семи-ярусной чайхане «Махмуджон ота», расположенной в городском парке. Чустский плов.',
            '13.30. – Свободное время в городском парке, любимом месте отдыха горожан и гостей города.',
            '14.00. – Продолжение экскурсии в Чусте.',
            'Чуст является одним из древних поселений Ферганской долины. В 2005 году городу исполнилось 3 500 лет. Чуст является центром традиционных художественных ремёсел, в частности по вышивке тюбетеек, национальных поясов и халатов. Но особую известность имеют чустские ножи. Чустский клинок пичок – древний и самый известный в Центральной Азии и, далеко за её пределами, нож. Чустские ножи всегда ценили за их уникальную форму, практичность, красоту и натуральные материалы.',
            'Посещение мастерской Баходиржона Мамажанова, сузангара в 8 поколении. Знакомство с процессом ручного производства чустских ножей.',
            '14.45. – Посещение мастерской Шаходатхон Тоштилляевой по вышивке национальных чустских тюбетеек. Мужское население Наманганской области преимущественно носит чустские головные уборы дуппи.',
            '15.30. – Посещение чустского базара. Свободное время.',
            '16.30. – Отправление в Наманган.',
            '17.30. – Прибытие в Наманган.',
            'В стоимость программы включено:',
            '- Комфортабельный автобус;',
            '- Профессиональный гид;',
            '- Обед в чайхане «Махмуджон ота» в Чусте;',
            '- Входные билеты в музеи и на памятники в Ахсикенте.',
        ]
    },
    {
        id: 4,
        title: 'Туристическая поездка в Гава.',
        description: 'Наманган май 27, 2024',
        image: tours_nam2,
        details: [
            'Направление: Наманган – Гава – Наманган.',
            'Продолжительность: 1 день.',
            '   Гава, горное селение в Чустском районе Наманганской области. Здесь красивая, не испорченная цивилизацией, природа, свежий, чистый воздух. Здесь протекает река Гавасай, берущая своё начало в Киргизии, на южных склонах Чаткальского хребта. Летом в селении Гава температура воздуха в среднем на 15 градусов ниже, чем в Намангане.',
            'На даче есть летний бассейн, теннисный стол, бадминтон.',
            '06.30. – Встреча с гостями в 5а микр., возле Эйфелевой башни.',
            '07.00. – Отправление на автобусе в Гава.',
            '09.00. – Прибытие в Гава на дачу. Размещение на топчанах.',
            '09.10. - Лёгкий завтрак, чай.',
            '09.45. – Свободное время. Купание в бассейне. Игра в теннис, бадминтон.',
            '13.00. – Обед. Котирма.',
            '14.00. - Начало экскурсии по селению Гава. Осмотр достопримечательностей. Прогулка по окрестностям Гава.',
            '16.00. – Возвращение на дачу. Свободное время.',
            '17.00. – Ранний ужин. Мастава.',
            '18.00. – Возвращение в Наманган.',
            '20.00. – Прибытие в Наманган.',
            'Конец программы.',
            'В стоимость тура включено:',
            '- Комфортабельный автобус;',
            '- Микроавтобус «Дамас» во время экскурсии по селению Гава;',
            '- Аренда дачи;',
            '- Питание (лёгкий завтрак, обед, ужин);',,
            '- Сопровождающий гид.'
        ]
    },
    {
        id: 5,
        title: 'Туристическая поездка на Арашанские озёра.',
        description: 'Наманган май 25, 2024',
        image: tours_nam3,
        details: [
            'Направление: Наманган – Арашаны – Наманган.',
            'Продолжительность: 1 день.',
            '04.30. – Встреча с гостями в 5а микр. возле Эйфелевой башни.',
            '05.00. – Отправление на микроавтобусах до кишлака Ертошсой на Камчикском перевале.',
            '07.30. – Прибытие в кишлак Ертошсой. Пересадка на автомобили «Нива». Отправление на Арашанские озёра.',
            'Трёхчасовой путь по абсолютному бездорожью длиной 54 км, устремляющийся в высь, напоминает адреналиновые гонки марафона «Париж – Дакар». Здесь есть всё: скорость, крутые виражи, клубы пыли от проезжающей навстречу машины, драйв от переживаемых ощущений…',
            'Повсюду мелькают тушканчики, табун лошадей спускается на водопой, облака становятся всё ниже и ниже…   ',
            '10.30. – Прибытие на Арашаны. Посещение озера Етим кул, расположенного на высоте 2 800 м над уровнем моря. До облаков рукой подать. Здесь достаточно прохладно, даже в самые знойные дни температура воздуха не поднимается выше 20 градусов тепла. Спускающаяся с горы к озеру льдина не успевает растаять за летний сезон.',
            '11.00. – Посещение озера Узун кул, самого длинного из всех 6 арашанских озёр. Здесь, на лугу, мы устраиваем привал.',
            '11.15. – Пикник (фастфуд, напитки, фрукты, печёное, сладости).',
            '12.00. – Поход к озёрам Ойна кул и Тулпор кул, расположенных на высоте 3 000 м над уровнем моря. Поход средней сложности и по времени в обе стороны занимает около двух часов.',
            '14.00. – Возвращение к озеру Узун кул.',
            '14.15. - Рассадка по машинам. Возвращение в Ертошсой.',
            '17.15. – Пересадка на микроавтобусы. Возвращение в Наманган.',
            '19.45. – Прибытие в Наманган.',
            'Конец программы.',
            'В стоимость тура включено:',
            '- Комфортабельные микроавтобусы по направлению Наманган – Ертошсой – Наманган;',
            '- Автомобиль «Нива» на перездах Ертошсой – Арашаны - Ертошсой;',
            '- Пикник (фастфуд, напитки, фрукты, печёное, сладости);',
            '- Сопровождающий гид.'
        ]
    },
    {
        id: 6,
        title: 'Туристическая поездка в Парда Турсун.',
        description: 'Наманган май 25, 2024',
        image: tours_nam4,
        details: [
            'Туристическая поездка в Парда Турсун.',
            'Направление: Наманган – Парда Турсун – Наманган.',
            '06.00. – Встреча с гостями возле Эйфелевой башни.',
            '06.30. – Отправление на автобусе в Парда Турсун.',
            '09.00. – Прибытие в Парда Турсун на дачу.',
            '09.10. - Лёгкий завтрак, чай.',
            '09.45. – Начало экскурсии по Парда Турсун. Осмотр достопримечательностей. Посещение Лолазор, Бургут Тог, Арчазор и Шаршара. ',
            '13.00. – Возвращение на дачу. Обед. Плов.',
            '14.00. – Свободное время.',
            '16.30. – Ранний ужин. Димляма.',
            '17.30. – Возвращение в Наманган.',
            '20.00. – Прибытие в Наманган.',
            'Конец программы.',
            'В стоимость тура включено:',
            '- Комфортабельный автобус;',
            '- Микроавтобус «Дамас» во время экскурсии по Парда Турсун;',
            '- Аренда дачи;',
            '- Питание (лёгкий завтрак, обед, ужин);',
            '- Сопровождающий гид;',
            '- Входные билеты на объекты посещения;',
            '- Канатная дорога.'
        ]
    },
    {
        id: 7,
        title: 'Туристическая поездка в Чадак.',
        description: 'Наманган май 25, 2024',
        image: tours_nam5,
        details: [
            'Направление: Наманган – Чадак – Наманган.',
            'Продолжительность: 1 день.',
            '06.00 – Встреча с гостями в 5а микр. возле Эйфелевой башни, ориентир кафе «Эвос».',
            '06.30. – Отправление на комфортабельном автобусе в Чадак.',
            '09.00. – Прибытие в Чадак, к месту «Чир-чир Момо» на берегу сая. Посещение «Плачущей скалы».',
            '09.30.  Переезд на дачу, размещение на топчанах.',
            '09.40. - Лёгкий завтрак, чаепитие.',
            '10.30. – Начало экскурсии по окрестностям Чадака на «Дамасах». Посещение большого водопада, древних захоронений периода зароастризма Муг тепа, пещеры глубиной 20 м.',
            '13.30. – Возвращение на дачу. Обед. Плов.',
            '14.30. – Свободное время. Дискотека на даче.',
            '17.00. – Ранний ужин. Димляма.',
            '18.00. – Возвращение в Наманган.',
            '21.00. – Прибытие в Наманган.'
        ]
    },
    {
        id: 8,
        title: 'Туристическая поездка в Баликкуль и Нанай.',
        description: 'Наманган мар 26, 2024',
        image: tours_nam6,
        details: [
            '06.00. – Встреча с гостями возле Эйфелевой башни.',
            '06.30. – Отправление на автобусе в Баликкуль.',
            '08.30. – Прибытие в селение Баликкуль.',
            'Посещение мемориального комплекса.',
            '09.15. – Переезд к месту захоронения матери Султона Увайсий.',
            '10.00. – Отправление в Нанай.',
            '11.15. – Прибытие в селение Нанай Янгикурганского района. Размещение на зоне отдыха у Равшана.',
            '11.30. – Обед. Бешбармак.',
            '12.30. – Экскурсия по местным достопримечательностям.',
            '15.30. – Возвращение на зону отдыха.',
            '16.30. – Ужин. Котирма.',
            '17.30. – Возвращение в Наманган.',
            '21.00. – Прибытие в Наманган.',
            'Конец программы.',
            'В стоимость тура включено:',
            '- Комфортабельный автобус;',
            '- Микроавтобус «Дамас» во время экскурсии по Нанаю;',
            '- Питание (обед, ужин);',
            '- Аренда топчана на даче;',
            '- Сопровождающий гид;',
            '- Входные билеты на объекты.'
        ]
    },
    {
        id: 9,
        title: 'Наманган - Чуст - Наманган',
        description: 'Наманган мар 26, 2024',
        image: tours_nam7,
        details: [
            'Чуст - это не только ножи и тюбетейки.',
            'Чуст, который вы ещё не знаете.',
            '07.45. - Встреча с гостями возле Эйфелевой башни в 5а микр.',
            '08.00. - Отправление в Чуст.',
            '09.00. - Мечеть хожи Эгамберди (1850 г.)',
            '10.00. - Отчопар эшон медресе.',
            '11.00. - Посещение мастерской Шарифжон уста. Мастер занимается изготовлением предметов из меди. И изготовлением музыкальных инструментов (карнай, сурнай)',
            '12.30. Обед в парке. Чустский плов.',
            '14.00. - Место поломничества - Зиёратгох Абдурахмон Ибн Авф. Здесь до наших дней сохранилась уникальная старинная чиллахона.',
            '15.00. По дороге мы проедем школу #75. Здесь когда то был дом поэта Махмуд хужа эшона. В последующем на этом месте открылась первая школа Чуста.',
            'Выезд в Наманган',
            '17.00. - Прибытие в Наманган.',
            'В стоимость входит:',
            '- Транспорт по всему маршруту;',
            '- Обед;',
            '- Услуги сопровождающего гида.'
        ]
    },
    {
        id: 10,
        title: 'Загадки истории',
        description: 'Наманган янв 16, 2024',
        image: tours_nam9,
        details: [
            '09.00. - Встреча возле Эйфелевой башни. В 5 а микр.',
            'Выезд в Новый Ахсикент.',
            '10.00. - Новый Ахсикент. Здесь нам покажут исторические экспонаты, найденные при раскопках. И хранилище со старинными книгами, которые чудом сохранились до наших времён.',
            '11.00. - Выезд в Старый Ахсикент. Музей под открытым небом.',
            '11.30.-13.30. - Экскурсия. История Ахсикента.',
            'И ЗДЕСЬ ЖЕ МЫ ПРИМЕМ УЧАСТИЕ В НАСТОЯЩИХ РАСКОПКАХ. Сейчас на территории Ахсикента работают историки и археологи.',
            '14.00. - Обед на берегу Сардарьи.',
            '15.30. - Прогулка. Катание на моторных лодках по Сырдарье.',
            '16.30. - Выезд в город.',
            '17.30. - Приезд в Наманган. К Эйфелевой башне в 5а микр.',
            'В стоимость входит:',
            '-Транспорт по всему маршруту;',
            '-Входные билеты в музеи;',
            '-Обед;',
            '-Катание на моторной лодке;',
            '-Услуги сопровождающего.'
        ]
    },
    {
        id: 11,
        title: 'Фотосессия в «Белой пустыне»',
        description: 'Наманган янв 16, 2024',
        image: tours_nam10,
        details: [
            'Авторский тур и профессиональная фотосессия от Тохира Турсунова.',
            '09.00. – Встреча с гостями в 5а микр., возле Эйфелевой башни. Отправление в кишлак Шаханд Туракурганского района.',
            '10.00. – Прибытие в Шаханд. Посещение местной мастерской по приготовлению вкуснейшей халвы и других восточных сладостей. Мастер-класс.',
            '11.30. – Отправление в Мингбулак.',
            '12.00. – Обед.',
            '14.00. – Мингбулакская пустыня «Ок кум». Прогулка по «Белой пустыне». Фотосессия.',
            '16.00. –  Отправление в Наманган.',
            '17.30. – Прибытие в Наманган.',
            'В стоимость входит:',
            '- Транспорт по всему маршруту;',
            '- Мастер-класс по приготовлению шахандской халвы;',
            '- Обед в чайхане;',
            '- Фотосессия.'
        ]
    },
    {
        id: 12,
        title: 'Я сейчас в Ташкенте. Очень надеюсь, что Вы всё поправите',
        description: 'Наманган янв 16, 2024',
        image: tours_nam11,
        details: [
            'Просто будем наслаждаться свежим воздухом. Будем общаться и кушать вкусный бешбармак!',
            '09.00. - Встреча возле Эйфелевой башни, 5а микрорайон.',
            'Выезд в Нанай.',
            '10.00. - Прибытие в Нанай. Размещение. Чаепитие.',
            '12.00. - Обед. Бешбармак.',
            '13.30. - Лёгкая прогулка по территории. Общение.',
            '16.00. - Ранний ужин.',
            '17.00. - Выезд в город.',
            '18.00. - Приезд в Наманган. К Эйфелевой башне в 5а микрорайон.',
            'В стоимость входит:',
            '-Транспорт Наманган - Нанай - Наманган;',
            '-Аренда места;',
            '-Сладости к чаю;',
            '-Обед. Бешбармак;',
            '-Ужин. Катирма.'
        ]
    },
    {
        id: 13,
        title: 'Гастрономическое путешествие в Учкурган',
        description: 'Наманган янв 16, 2024',
        image: tours_nam12,
        details: [
            '08.15. – Встреча с гостями возле Эйфелевой башни.',
            '08.30.- Отправление в Учкурган.',
            '09.30. – Прибытие в Учкурган. Посещение предприятия «Ideal Tomat» по производству томатной пасты и других консервированных продуктов.',
            '11.00. – Посещение хлопкопрядильной фабрики «Uztex». Одно из самых крупных СП в Узбекистане.',
            '12.00. Посещение рыбного хозяйства.',
            '12.30. - Обед. Форель.',
            '13.30. – Посещение плантаций по выращиванию клубники и голубики.',
            '15.00. – Посещение пасеки, где пчёл разводит женщина - пчеловод.',
            '16.00. – ГЭС на Ферганском канале.',
            '17.00. - Отправление в Наманган.',
            '18.00. - Прибытие в Наманган, к Эйфелевой башне.',
            'В стоимость программы включено:',
            '- Транспорт;',
            '- Обед в чайхане, форель;',
            '- Сопровождающий.'
        ]
    }
];

const ToursPage = () => {
    const { id } = useParams();
    const tour = tours.find(t => t.id === parseInt(id));

    if (!tour) {
        return <div>Тур не найден</div>;
    }

    return (
        <main>
            <div className="top-back">
                <div className="content">
                    <h2 className='title-text' style={{ fontSize: '50px' }}>{tour.title}</h2>
                </div>
            </div>
            <div className="page-tours">
                <div className="tours-page"> 
                    <img src={tour.image} alt={tour.title} className="tours-part-image" />
                    <p style={{color: '#000'}}>{tour.description}</p>
                    {tour.details.map((detail, index) => (
                        <p style={{color: '#000'}} key={index}>{detail}</p>
                    ))}
                </div>
            </div>
            <Footer />
        </main>
    );
};

export default ToursPage;
