import React, { useState, useMemo } from 'react';
import { Link } from "react-router-dom";
import './Navbar.css';
import { useLang } from '../redux/selectors';
import localData from "../redux/localization/locale.json"

import Logo from '../images/logo.png';

const Navbar = ({ isScrolled }) => {
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);
  const [isMobile, setIsMobile] = useState(false);


  return (
    <nav className={`navbar ${isScrolled ? 'fixed' : ''}`}>
      <Link to="/"><div className="logo"><img src={Logo} alt="" /></div></Link>
      <ul className={isMobile ? "nav-links-mobile" : "nav-links"} onClick={() => setIsMobile(false)}>
        <li><Link to="/">{langData['home']}</Link></li>
        <li><Link to="/about">{langData['about']}</Link></li>
        <li className="dropdown">
          <Link to="/tours">{langData['tours']}</Link>
          <ul className="dropdown-menu">
            <li><Link to="/namangan">{langData['tour-nam']}</Link></li>
            <li><Link to="/fergana">{langData['tour-fer']}</Link></li>
            <li><Link to="/andijan">{langData['tour-and']}</Link></li>
            <li><Link to="/tashkent">{langData['tour-tash']}</Link></li>
            <li><Link to="/kazakhstan">{langData['tour-kzh']}</Link></li>
            <li><Link to="/kirgizistan">{langData['tour-kg']}</Link></li>
            <li><Link to="/tadjikistan">{langData['tour-td']}</Link></li>
          </ul>
        </li>

        <li><Link to="/gallery">{langData['photo-gallery']}</Link></li>
        <li><Link to="/news">{langData['news']}</Link></li>
        <li><Link to="/contact">{langData['contact']}</Link></li>
      </ul>
      <button className="mobile-menu-icon" onClick={() => setIsMobile(!isMobile)}>
        {isMobile ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
      </button>
    </nav>
  );
};

export default Navbar;
